import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";

const Services = () => {
    return (<>
        <Container className="mt-5 mb-5" id="services">
            <Row>
                <Col>
                    <h2 className="text-center text-capitalize m-5">Our Services</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3} className="mb-4">
                    <Card className="shadow">
                        <Card.Img variant="top" src="/images/services/mechanical.jpg"/>
                        <Card.Body>
                            <Card.Title>Mechanical engineering</Card.Title>
                            <Card.Text>
                                Designing, Prototyping and Production of Mechanical parts.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={3} className="mb-4">
                    <Card className="shadow">
                        <Card.Img variant="top" src="/images/services/3d-printing.jpg"/>
                        <Card.Body>
                            <Card.Title>3D Printing</Card.Title>
                            <Card.Text>
                                Designing, Prototyping and Production of 3D Printed parts.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={3} className="mb-4">
                    <Card className="shadow">
                        <Card.Img variant="top" src="/images/services/software.jpg"/>
                        <Card.Body>
                            <Card.Title>Software engineering</Card.Title>
                            <Card.Text>
                                Designing, coding and testing app and web applications.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={3} className="mb-4">
                    <Card className="shadow">
                        <Card.Img variant="top" src="/images/services/electronic.jpg"/>
                        <Card.Body>
                            <Card.Title>Electronic engineering</Card.Title>
                            <Card.Text>
                                Designing, Prototyping and Production of electronics devices.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </Container>
    </>);
}

export default Services
