import React from "react";
import {Container, Nav, Navbar} from "react-bootstrap";

const TopBar = () => {
    return (<>
        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" sticky="top">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                      src="/images/logo.svg"
                      height="35"
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                {/*<Navbar.Toggle/>*/}
                {/*<Navbar.Collapse className="justify-content-end">*/}
                {/*    <Navbar.Text>*/}
                {/*        <Nav.Link href="#contacts">Contacts</Nav.Link>*/}
                {/*    </Navbar.Text>*/}
                {/*</Navbar.Collapse>*/}
            </Container>
        </Navbar>
    </>);
}

export default TopBar
