import React from "react";
import Typewriter from 'typewriter-effect';
import TopBar from "../components/TopBar";

const RandomVideo = (): string => {
    const videos = [
        "/videos/production ID_4941457.mp4",
        "/videos/pexels-mikhail-nilov-9244547.mp4",
        "/videos/pexels-kelly-lacy-6046350.mp4",
    ];

    return videos[Math.floor(Math.random() * videos.length)];
}

const Header = () => {
    return (<>
          <TopBar/>
          <div className="videoBackground">
              <div className="overlay"/>

              <video playsInline={true} autoPlay={true} muted={true} loop={true}>
                  <source src={RandomVideo()} type="video/mp4"/>
              </video>

              <div className="container h-100">
                  <div className="d-flex h-100 text-center align-items-center">
                      <div className="w-100 text-white">
                          <h2 className="mb-0 display-3">
                              We make {' '}
                              <Typewriter
                                options={{
                                    strings: ['Electronics', 'Mechanics', '3D Printing', 'Cloud Native Applications', 'Ideas']
                                      .map(value => ({value, sort: Math.random()}))
                                      .sort((a, b) => a.sort - b.sort)
                                      .map(({value}) => value),
                                    autoStart: true,
                                    loop: true,
                                }}
                              />
                          </h2>
                      </div>
                  </div>
              </div>
          </div>
      </>
    );
}

export default Header
