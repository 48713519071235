import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";

const WhoWeAre = () => {
    return (<>
        <Container className="mt-5 mb-5" id="who-we-are">
            <Row>
                <Col>
                    <h2 className="text-center text-capitalize m-5">Who We Are</h2>
                </Col>
            </Row>
            <Row>
                <Col className="text-right d-none d-sm-block">
                    <Image className="shadow" src="/images/who-we-are.jpg" width="100%"/>
                </Col>
                <Col xs={12} sm={6}>
                    <h5>An Exceptional Team with the most Heterogeneous Skills.</h5>
                    <p>
                        We select only the best talents to provide a service that is always at the top
                    </p>
                    <h6 className="mt-5">
                        Our team consists of:
                    </h6>
                    <ul>
                        <li>3D Printing experts</li>
                        <li>Software engineers</li>
                        <li>Devops engineers</li>
                        <li>Mechanical engineers</li>
                        <li>Electronic engineers</li>
                    </ul>
                </Col>

            </Row>
        </Container>
    </>);
}

export default WhoWeAre
