import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGithub, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {Link} from "gatsby";

const Footer = () => {
    return (<>
        <div id="footer" className="mt-5">
            <Container className="pt-4 pb-4">
                <Row>
                    <Col>
                        <img
                          src="/images/logo.svg"
                          height="35"
                          className="d-inline-block align-top"
                          alt="React Bootstrap logo"
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div style={{width: "150px", borderTop: "1px solid #FFF"}}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Link className="link" to="https://github.com/manno-industries" target="_blank">
                            <FontAwesomeIcon size="lg" icon={faGithub}/>
                        </Link>
                        {' '}
                        <Link className="link" to="https://www.instagram.com/manno_industries/" target="_blank">
                            <FontAwesomeIcon size="lg" icon={faInstagram}/>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    </>);
}

export default Footer;
