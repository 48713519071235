import * as React from "react"
import {Helmet} from "react-helmet"

import '../assets/scss/main.scss';
import Header from "../sections/Header";
import WhoWeAre from "../sections/WhoWeAre";
import Services from "../sections/Services";
import Contacts from "../sections/Contacts";
import Footer from "../sections/Footer";

const IndexPage = () => {
    return (<>
        <div id="home"/>
        <Helmet>
            <title>Manno Industries - Engineering services</title>

            <meta charSet="utf-8"/>

            <link rel="canonical" href="https://manno.industries"/>
            <meta name="description" content="Engineering services"/>
            <meta name="keywords"
                  content="Engineering, Mechanics, Electronics, 3D Printing, Software Engineer, Devops Engineer"/>
            <meta name="robots" content="all"/>
            <meta name="revisit-after" content="1"/>
            <meta name="language" content="en-GB"/>
            <meta name="charset" content="utf-8"/>
            <meta name="copyright" content="Manno Industries"/>
            <meta name="author" content="Alessandro Manno"/>

            <meta name="title" content="Manno Industries"/>

            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://manno.industries/"/>
            <meta property="og:title" content="Manno Industries"/>
            <meta property="og:description" content="Engineering services"/>
            <meta property="og:image" content="https://manno.industries/images/social_cover.png"/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://manno.industries/"/>
            <meta property="twitter:title" content="Manno Industries"/>
            <meta property="twitter:description" content="Engineering services"/>
            <meta property="twitter:image" content="https://manno.industries/images/social_cover.png"/>
        </Helmet>
        <Header/>
        <WhoWeAre/>
        <Services/>
        <Contacts/>
        <Footer/>
    </>)
}

export default IndexPage
