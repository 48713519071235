import React, {FormEventHandler, LegacyRef, useState} from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

const Contacts = () => {
    const recaptchaRef = React.useRef<ReCAPTCHA>();
    const formRef = React.useRef<HTMLFormElement | null>(null);

    const [email, setEmail] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [form, setForm] = useState<boolean | null>(null);

    const onSuccess = () => {
        setForm(true)
    }

    const onError = () => {
        setForm(false)
    }

    const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        recaptchaRef.current?.executeAsync().then(captcha => {
            fetch('https://api.manno.industries', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    text,
                    email,
                    captcha,
                })
            }).then(onSuccess)
              .catch(onError)
        }).catch(onError);
    }

    return (<>
        <Container className="mt-5 mb-5" id="contacts">
            <Row>
                <Col>
                    <h2 className="text-center text-capitalize m-5">Contact us</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert variant="danger" show={form === false} onClose={() => setForm(null)} dismissible
                           transition={false}>
                        <Alert.Heading>Oh noo! C'è stato un errore!</Alert.Heading>
                        <p>
                            Purtroppo il tuo messaggio ha avuto qualche problema di invio,
                            stiamo già lavorando per risolverlo, a presto!
                        </p>
                    </Alert>
                    <Alert variant="success" show={form === true} onClose={() => setForm(null)} dismissible
                           transition={false}>
                        <Alert.Heading>Il tuo messaggio è in viaggio!</Alert.Heading>
                        <p>
                            Riceverai una mail per conferma, grazie mille!
                        </p>
                    </Alert>
                </Col>
            </Row>
            {form === null &&
                <Row>
                    <Col>
                        <Form ref={formRef} onSubmit={onSubmit}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </Form.Group>

                            <ReCAPTCHA
                                ref={recaptchaRef as LegacyRef<ReCAPTCHA>}
                                size="invisible"
                                sitekey="6Ld0D10fAAAAACmEdtzBJ8jVRq8e9x5wgAVwuCTg"
                            />

                            <Button
                                variant="dark"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Form>
                    </Col>

                </Row>
            }
        </Container>
    </>);
}

export default Contacts
